
<template>
  <div>
    <Row :gutter="8">
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
                <Select size="small" v-model="companyId" placeholder="收款方" clearable >
                  <Option v-for="(channel,index) in channellist" :key="index" :value="channel.id">{{channel.name}}</Option>
                </Select>
            </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <DatePicker
          placeholder="合同发布时间"
          v-model="publishDate"
          size="small"
          type="daterange"
          style="width: 100%"
        ></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <i-input
          placeholder="合同编号、合同名称、甲方"
          size="small"
          v-model="query.keyword"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="6" :md="6" :lg="4" class="m-b-5" style="min-width:150px;">
        <i-button
          class="m-r-10"
          type="primary"
          icon="ios-search"
          size="small"
          @click="handleSearch()"
          >搜索</i-button
        ><i-button
          type="success"
          size="small"
          @click="handleExportExcel"
          >导出Excel</i-button
        >
      </i-col>
    </Row>

    <!-- <Row
      class="m-t-5 p-t-5 p-b-5"
      style=" color: #44bd32; background: #3b3b3b"
    >
      <i-col span="12" style="padding: 2px 18px;color:#fff;">全部合计</i-col>
      <i-col span="3" style="padding: 2px 18px">{{
        formatMoney(groupSummary.amount)
      }}</i-col>
      <i-col span="3" style="padding: 2px 18px">{{
        formatMoney(groupSummary.receivedAmount)
      }}</i-col>
      <i-col span="3" style="padding: 2px 18px">{{
        formatMoney(groupSummary.overdueNotReceiveAmount)
      }}</i-col>
      <i-col span="3"></i-col>
    </Row> -->
    <Table stripe :data="contractList" :columns="tableColumns"></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handlePageChanged"
      ></Page>
    </div>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetPublishDay, GetCurrentSchedule } from '@/utils/dateFormat'
import {
  getdebtgrouppage,
  getdebtgroupsummary
} from '@/api/scp/debtgroupcashflow'
import { getCompanyChild } from '@/api/os/company'
import { downloadFileRequest } from '@/utils/download'
export default {
  props: {
    params: Object
  },
  data () {
    return {
      companyId: '',
      channellist: [],
      publishDate: '',
      query: {
        keyword: '',
        startDate: '',
        endDate: '',
        pageNumber: 1,
        pageSize: 15
      },
      contractList: [],
      total: 0,
      tableColumns: [
        {
          title: '合同编号',
          key: 'relateCode'
        },
        {
          title: '合同名称',
          key: 'relateName'
        },
        {
          title: '甲方',
          key: 'payerAccountName'
        }, {
          title: '收款方',
          key: 'receiverAccountName'
        },
        {
          title: '合同起止日',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [h('span', this.formatSchuedle(params.row).value)])
          }
        },
        {
          title: '合同总额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [h('span', { class: 'money' }, toMoney(params.row.amount))])
          }
        },
        {
          title: '累计收款额',
          key: 'receivedAmount',
          render: (h, params) => {
            return h('div', [h('span', { class: 'money' }, toMoney(params.row.receivedAmount))])
          }
        },
        {
          title: '逾期未收款额',
          key: 'overdueNotReceiveAmount',
          render: (h, params) => {
            return h('div', [
              h('span', { class: 'money' }, toMoney(params.row.overdueNotReceiveAmount))
            ])
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleShowDetail(params.row)
                    }
                  }
                },
                '详情'
              ),
              this.isAuth('payment_add') ? h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleEdit(params.row)
                    }
                  }
                },
                '录入'
              ) : null
            ])
          }
        }
      ]
    }
  },
  created () {
    getCompanyChild({ companyId: this.$store.getters.token.userInfo.companyId, type: this.$store.getters.token.userInfo.publisherId === this.$store.getters.token.userInfo.companyId ? 1 : 2, childType: 2, companyType: 1 }).then(res => {
      let array = [{
        id: res.companyId, name: res.companyName
      }]
      if (res.children.length) {
        array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
      }
      this.channellist = array
    })
    this.initPageData()
    this.initGroupSummary()
  },

  methods: {
    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    formatSchuedle (data) {
      return {
        value: GetCurrentSchedule(data.relateStartDate, data.relateEndDate),
        days: GetPublishDay(data.relateStartDate, data.relateEndDate)
      }
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handleShowDetail (item) {
      this.$store.commit('set_payment_chkContract', item)
      this.$store.commit('set_payment_isEdit', false)
      this.$store.commit('set_payment_showLeft', true)
    },
    handleEdit (item) {
      this.$store.commit('set_payment_chkContract', item)
      this.$store.commit('set_payment_isEdit', true)
      this.$store.commit('set_payment_showLeft', true)
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initPageData()
    },
    handleSearch () {
      this.query.pageNumber = 1

      this.query.startDate = this.formatDate(this.publishDate[0])
      this.query.endDate = this.formatDate(this.publishDate[1])
      this.query.companyId = this.companyId
      this.initPageData()
      this.initGroupSummary()
    },
    initGroupSummary () {
      getdebtgroupsummary(this.query).then((res) => {
        this.$store.commit('set_payment_contractSummary', res)
        this.$store.commit('set_payment_summaryType', '1')
      })
    },
    initPageData () {
      getdebtgrouppage(this.query).then((res) => {
        this.contractList = res.list
        this.total = res.totalRow
      })
    },
    formatDate (date) {
      // if (date === '' || date === undefined) {
      if (!date) {
        return null
      } else {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    },
    handleExportExcel () {
      const query = Object.assign({}, this.query)
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 +
          '/ooh-scp/v3/debtgroupcashflow/exportdebtgroupexcel',
        query,
        '合同收付列表.xlsx'
      )
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.payment.beginUpdate
    }
  },
  watch: {
    beginUpdate (val) {
      this.initPageData()
    }

  }
}
</script>
