import request from '@/utils/requestV2'
const qs = require('qs')
// 账目组列表
export function getdebtgrouppage (data) {
  return request({
    url: '/ooh-scp/v3/debtgroupcashflow/getdebtgrouppage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 账目组情况汇总
export function getdebtgroupsummary (data) {
  return request({
    url: '/ooh-scp/v3/debtgroupcashflow/getdebtgroupsummary',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 收付明细列表
export function getdebtgroupcashflowpage (data) {
  return request({
    url: '/ooh-scp/v3/debtgroupcashflow/getdebtgroupcashflowpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 收付明细汇总
export function getdebtgroupcashflowsummary (data) {
  return request({
    url: '/ooh-scp/v3/debtgroupcashflow/getdebtgroupcashflowsummary',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 作废一笔收付款
export function invaliddebtgroupcashflow (data) {
  return request({
    url: '/ooh-scp/v3/debtgroupcashflow/invaliddebtgroupcashflow',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 收付一笔业务款项
export function adddebtgroupcashflow (data) {
  return request({
    url: '/ooh-scp/v3/debtgroupcashflow/adddebtgroupcashflow',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取收付款导入记录（分页）
export function getdebtgroupcashflowimportspage (data) {
  return request({
    url: '/ooh-scp/v3/debtgroupcashflow/getdebtgroupcashflowimportspage',
    method: 'post',
    data: qs.stringify(data)
  })
}
